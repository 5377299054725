// Body
$body-bg: #fff;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #9A0018;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$primary: #233d92;

// Typography
$font-family-sans-serif: 'Helvetica', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.2;
$headings-color: $primary;

// Headings
$h1-font-size: 22px;
$h2-font-size: 22px;
$h3-font-size: 20px;
$h4-font-size: 18px;

// Nav
$navbar-light-active-color: $primary;
$nav-link-font-size: 16px;

// Card
$card-spacer-y: 0;
$card-spacer-x: 0;
