//
// Image Grids
//

.grid-image {
    display: flex;
    align-items: center;
    height: 100px;
    margin-bottom: 12px;

    img {
        max-height: 100%;
    }
}
