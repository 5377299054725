//
// Navbar
//

.navbar-brand img {
    max-width: 250px;
    @include media-breakpoint-up(sm) {
        max-width: 100%;
    }
}
