//
// Members Carousel
//

.members-carousel {
    width: 100%;
    .swiper-slide {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        img {
            object-fit: contain;
            height: 100px;
        }
    }
}
