//
// Sticky Footer.
//

$footer-height: 213px;

.content {
    min-height: calc(100vh - #{$footer-height});
}

footer {
    height: $footer-height;
}
